/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Testimonial } from 'src/types/testimonial';
import { TestimonialsCarousel } from './carousel';
import { Text } from 'src/components/core/text';
import { Title } from 'src/components/layout/homepage/styles';
import { media } from 'src/styles/utils/media';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  hideBackgroundCircles?: boolean;
  items: Testimonial[];
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  color: var(--color-gray700);
  overflow-x: clip;
  overflow-y: visible;
  padding: var(--vertical-spacing) 0 0;
  position: relative;
`;

/**
 * `ContainerGrid` styled component.
 */

const ContainerGrid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  position: relative;
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 3rem;
  position: relative;

  ${media.min.md`
    grid-column-end: span 5;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 1 / -1;
  /* Firefox content jumping fix */
  max-width: 100%;

  ${media.min.md`
    grid-column: 4 / span 6;
  `}
`;

/**
 * `BlurImage` styled component.
 */

const BlurImage = styled(Image)`
  transform: translate(0, -10%);
`;

/**
 * Export `Testimonials` component.
 */

export function Testimonials({ hideBackgroundCircles, items }: Props) {
  const { t } = useTranslation('common');
  const isDesktop = useBreakpoint('sm');

  if (isEmpty(items)) {
    return null;
  }

  return (
    <Wrapper>
      {isDesktop && !hideBackgroundCircles && (
        <BlurImage
          alt={'stain'}
          fill
          objectFit={'contain'}
          objectPosition={'top left'}
          src={'/images/bg-left.png'}
        />
      )}

      <ContainerGrid>
        <Header>
          <Title
            as={'h2'}
            data-scroll={'fade-in-up'}
            data-scroll-offset={'25%'}
          >
            {t('sections.testimonials.title')}
          </Title>

          <Text
            as={'h3'}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={100}
            data-scroll-offset={'20%'}
            variant={'h1'}
          >
            {t('sections.testimonials.lead')}
          </Text>
        </Header>

        <Content
          data-scroll={'fade-in-up'}
          data-scroll-animation-delay={300}
          data-scroll-offset={'20%'}
        >
          <TestimonialsCarousel items={items} />
        </Content>
      </ContainerGrid>
    </Wrapper>
  );
}
