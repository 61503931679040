/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Marquee } from 'src/components/marquee';
import { TeamMember } from 'src/types/team-member';
import { Text } from 'src/components/core/text';
import { Title } from 'src/components/layout/homepage/styles';
import { media } from 'src/styles/utils/media';
import { useMemo } from 'react';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useTranslation } from 'next-i18next';
import buttonIcon from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  members: TeamMember[];
};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  --team-grid-gap: 1rem;

  background: var(--gradient-team);
  color: var(--color-white);
  overflow: hidden;

  ${media.min.sm`
    height: 100vh;
    max-height: 900px;
  `}

  ${media.min.lg`
    --team-grid-gap: 2rem;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text)`
  margin-bottom: 3rem;
`;

/**
 * `ContainerGrid` styled component.
 */

const ContainerGrid = styled(Container)`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  height: 100%;
  overflow: hidden;

  ${media.min.sm`
    grid-template-areas: 'content . scrollContent scrollContent';
    grid-template-columns: 2.5fr 0.5fr 4fr;
  `}

  ${media.min.md`
    grid-template-columns: 3fr 1fr 6fr;
  `}

  ${media.min.lg`
    grid-template-areas: 'content . scrollContent .';
    grid-template-columns: 2fr 1fr 6fr 1fr;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-self: start;
  display: grid;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  padding: min(14vw, 128px) 0 min(14vw, 128px);
  position: relative;
  z-index: 1;

  ${media.min.sm`
    padding-right: 0;
    grid-area: content;
  `}
`;

/**
 * `ScrollContent` styled component.
 */

const ScrollContent = styled.div`
  display: grid;
  grid-area: scrollContent;
  grid-auto-flow: column;
  grid-gap: var(--team-grid-gap);
  height: 100%;

  ${media.max.sm`
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  `}
`;

/**
 * `ScrollMarqueeWrapper` styled component.
 */

const ScrollMarqueeWrapper = styled.div<{ rightColumn?: boolean }>`
  height: 100%;
  opacity: 0.1;
  position: relative;

  ${media.min.sm`
    opacity: 1;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image).attrs({
  height: 488,
  width: 366
})`
  margin-bottom: var(--team-grid-gap);
  object-fit: cover;
  width: 100%;
`;

/**
 * `TeamSection` component.
 */

export function TeamSection(props: Props) {
  const { members } = props;
  const { t } = useTranslation('common');
  const resolveRoute = useRouteResolver();
  const [upwardsMembers, downwardsMembers] = useMemo(() => {
    if (!members?.length) {
      return [[], []];
    }

    const half = Math.ceil(members.length / 2);

    return [members.slice(0, half), members.slice(half)];
  }, [members]);

  return (
    <Section>
      <ContainerGrid>
        <Content>
          <Title
            data-scroll={'fade-in-up'}
            data-scroll-offset={'20%'}
          >
            {t('sections.team.title')}
          </Title>

          <Description
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={200}
            data-scroll-offset={'20%'}
          >
            {t('sections.team.lead')}
          </Description>

          <Button
            colorTheme={'whiteOutlined'}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={300}
            data-scroll-offset={'10%'}
            href={resolveRoute('team')}
            icon={buttonIcon}
            style={{ marginTop: 1, width: 'max-content' }}
          >
            {t('actions.viewAll')}
          </Button>
        </Content>

        <ScrollContent>
          <ScrollMarqueeWrapper
            data-scroll
            data-scroll-direction={'vertical'}
            data-scroll-speed={4}
          >
            <Marquee
              totalItems={upwardsMembers?.length}
              upwards
            >
              {upwardsMembers.map(image => (
                <StyledImage
                  alt={image.titleSeo}
                  key={image.id}
                  src={image.image}
                />
              ))}
            </Marquee>
          </ScrollMarqueeWrapper>

          <ScrollMarqueeWrapper
            data-scroll
            data-scroll-direction={'vertical'}
            data-scroll-speed={-4}
          >
            <Marquee totalItems={downwardsMembers?.length}>
              {downwardsMembers.map(image => (
                <StyledImage
                  alt={image.titleSeo}
                  key={image.id}
                  src={image.image}
                />
              ))}
            </Marquee>
          </ScrollMarqueeWrapper>
        </ScrollContent>
      </ContainerGrid>
    </Section>
  );
}
