/**
 * Module dependencies.
 */

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/effect-creative';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import {
  A11y,
  Autoplay,
  Controller,
  EffectCreative,
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

import {
  Children,
  ReactNode,
  isValidElement,
  useEffect,
  useState
} from 'react';

import { Image } from 'src/components/core/image';
import { Link } from 'src/components/core/links/link';
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from 'swiper/react';
import { Text } from 'src/components/core/text';
import { breakpoints } from 'src/styles/utils/breakpoints';
import { media } from 'src/styles/utils/media';
import styled from 'styled-components';

/**
 * `SlideProps` type.
 */

type SlideProps = {
  children?: ReactNode;
  className?: string;
  href?: string;
  imageUrl: string;
  index?: number;
  lead?: ReactNode;
  title?: string;
};

/**
 * `defaultModules` config.
 */

const defaultModules = [A11y, Autoplay, Controller, Keyboard, Navigation];

/**
 * `carouselProps` config.
 */

const carouselProps: Record<
  'fadeText' | 'gallery' | 'slide' | 'galleryCenter',
  Readonly<Partial<SwiperProps>>
> = {
  fadeText: {
    creativeEffect: {
      next: {
        opacity: 0,
        translate: ['25%', 0, 0]
      },
      prev: {
        opacity: 0,
        translate: [0, 0, -100]
      }
    },
    effect: 'creative',
    grabCursor: true,
    modules: [...defaultModules, Pagination, EffectCreative],
    slidesPerView: 1,
    spaceBetween: 32
  },
  gallery: {
    centeredSlides: true,
    grabCursor: true,
    modules: defaultModules,
    slidesPerView: 1,
    spaceBetween: 32
  },
  galleryCenter: {
    centeredSlides: true,
    grabCursor: true,
    modules: defaultModules,
    slidesPerView: 1,
    spaceBetween: 32
  },
  slide: {
    breakpoints: {
      [breakpoints.ms]: {
        slidesPerView: 1.3,
        spaceBetween: 32
      }
    },
    grabCursor: true,
    modules: defaultModules,
    slidesPerView: 1.2,
    spaceBetween: 24
  }
};

/**
 * Export `CarouselProps` type.
 */

export type CarouselProps = SwiperProps & {
  animation?: keyof typeof carouselProps;
  controlElements?: ReactNode | null;
};

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  padding-top: 60%;
  position: relative;

  ${media.max.sm`
    height: 33vmax;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  padding: 0 0.5rem;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: url('/images/cursor-drag.png') 33 33, pointer;
`;

/**
 * Export `CardSlide` component.
 */

export function CardSlide(props: SlideProps) {
  const { children, href, imageUrl, index, lead, title, ...rest } = props;
  const Wrapper = href ? StyledLink : 'div';

  return (
    <SwiperSlide key={imageUrl}>
      <Wrapper href={href}>
        <ImageWrapper {...rest}>
          <Image
            alt={title ?? `Image ${index}`}
            fill
            src={imageUrl}
          />
        </ImageWrapper>

        <Content>
          {children ? (
            children
          ) : (
            <>
              {lead &&
                (isValidElement(lead) ? (
                  lead
                ) : (
                  <Text variant={'small'}>{lead}</Text>
                ))}

              {title && <Text>{title}</Text>}
            </>
          )}
        </Content>
      </Wrapper>
    </SwiperSlide>
  );
}

/**
 * `swiperInitialState` constant.
 */

const swiperInitialState = {
  isFirstSlide: true,
  isLastSlide: false,
  realIndex: 0
};

/**
 * Export `useCarousel` hook.
 */

export function useCarousel() {
  const swiper = useSwiper();
  const [state, setState] =
    useState<typeof swiperInitialState>(swiperInitialState);

  useEffect(() => {
    swiper.on('slideChange', swipe => {
      setState({
        isFirstSlide: swipe.isBeginning,
        isLastSlide: swipe.isEnd,
        realIndex: swipe.realIndex
      });
    });
  }, [swiper]);

  return {
    carousel: swiper,
    carouselState: state
  };
}

/**
 * Export `Carousel` component.
 */

export function Carousel(props: CarouselProps) {
  const { animation, children, controlElements, ...rest } = props;

  return (
    <Swiper
      {...carouselProps[animation ?? 'slide']}
      {...rest}
    >
      <div
        className={'swiper-test'}
        style={{ overflow: 'clip' }}
      >
        {Children.map(children, child => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </div>

      {controlElements}
    </Swiper>
  );
}
