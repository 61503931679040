/**
 * Module dependencies.
 */

import { ReactNode, useMemo } from 'react';
import { ifProp, prop } from 'styled-tools';
import styled, { keyframes } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  totalItems: number;
  upwards?: boolean;
};

/**
 * `scroll` keyframes.
 */

const scroll = keyframes`
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visibleOverflow?: boolean }>`
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div<Pick<Props, 'upwards'> & { speed: string }>`
  align-items: center;
  animation: ${scroll} ${prop('speed')} linear infinite;
  animation-direction: ${ifProp('upwards', 'normal', 'reverse')};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  min-width: 100%;
  overflow: visible;
  z-index: 1;
`;

/**
 * Export `Marquee` component.
 */

export function Marquee({ children, totalItems, upwards, ...rest }: Props) {
  const speed = useMemo(() => `${totalItems * 10 + 100}s`, [totalItems]);

  return (
    <Wrapper {...rest}>
      <Content
        speed={speed}
        upwards={upwards}
      >
        {children}
      </Content>

      <Content
        aria-hidden
        speed={speed}
        upwards={upwards}
      >
        {children}
      </Content>

      <Content
        aria-hidden
        speed={speed}
        upwards={upwards}
      >
        {children}
      </Content>
    </Wrapper>
  );
}
