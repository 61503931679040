/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * Export `Title` styled component.
 */

export const Title = styled(Text).attrs({
  block: true,
  variant: 'subtitle2'
})`
  margin-bottom: 0;
`;
/**
 * Export `Lead` styled component.
 */

export const Lead = styled(Text).attrs({
  block: true,
  variant: 'display2'
})`
  margin-bottom: 3rem;
`;
