/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Lead, Title } from 'src/components/layout/homepage/styles';
import { RawHtml } from 'src/components/core/raw-html';
import { media } from 'src/styles/utils/media';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import buttonIcon from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  action?: {
    label?: string;
    url?: string;
  };
  animationDelay?: number;
  content: Array<{
    description?: string;
    lead?: string;
    title: string;
  }>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  color: var(--color-gray700);
  overflow-x: clip;
  overflow-y: visible;
  padding: var(--vertical-spacing) 0;
  position: relative;
`;

/**
 * `ContentGrid` styled component.
 */

const ContentGrid = styled.div`
  display: grid;
  grid-template-areas: 'lead' 'title' 'description';
  grid-template-columns: 1fr;

  ${media.min.sm`
    grid-template-areas:
      'lead  lead     lead            .           .'
      'title title    title           .           .'
      '.     description  description description .';
    grid-template-columns: 1fr 2fr 5fr repeat(2, 1fr);
  `}

  ${media.min.lg`
    grid-template-areas:
      'lead  lead     lead        . .'
      'title title    title       . .'
      '.     .        description . .';
  `}
`;

/**
 * `ActionGrid` styled component.
 */

const ActionGrid = styled.div`
  display: grid;
  grid-template-areas: 'action';
  grid-template-columns: 1fr;

  ${media.min.sm`
    grid-template-areas: '. action action action .';
    grid-template-columns: 1fr 2fr 5fr repeat(2, 1fr);
  `}

  ${media.min.lg`
    grid-template-areas: '. . action . .';
  `}
`;

/**
 * `BlurImage` styled component.
 */

const BlurImage = styled(Image)`
  transform: translate(0, -10%);
`;

/**
 * `DescriptiveSection` component.
 */

export function DescriptiveSection(props: Props) {
  const { action, animationDelay = 0, content } = props;
  const isDesktop = useBreakpoint('sm');

  return (
    <Wrapper>
      {isDesktop && (
        <BlurImage
          alt={'stain'}
          fill
          objectFit={'contain'}
          objectPosition={'top right'}
          src={'/images/bg-right.png'}
        />
      )}

      <Container>
        {content?.map(({ description, lead, title }, index) => (
          <ContentGrid key={index}>
            {lead && (
              <Title
                data-scroll={'fade-in-up'}
                data-scroll-animation-delay={animationDelay}
                data-scroll-offset={'20%'}
                style={{ gridArea: 'lead' }}
              >
                {lead}
              </Title>
            )}

            <Lead
              as={'h2'}
              data-scroll={'fade-in-up'}
              data-scroll-animation-delay={animationDelay + 100}
              data-scroll-offset={'20%'}
              style={{ gridArea: 'title', marginBottom: '3rem' }}
            >
              {title}
            </Lead>

            {description && (
              <div
                data-scroll={'fade-in-up'}
                data-scroll-animation-delay={animationDelay + 100}
                data-scroll-offset={'20%'}
                style={{ gridArea: 'description', marginBottom: '3rem' }}
              >
                <RawHtml>{description}</RawHtml>
              </div>
            )}
          </ContentGrid>
        ))}

        {!!action?.label && !!action?.url && (
          <ActionGrid>
            <div
              data-scroll={'fade-in-up'}
              data-scroll-offset={'20%'}
              style={{ gridArea: 'action' }}
            >
              <Button
                colorTheme={'greenOutlined'}
                href={action?.url}
                icon={buttonIcon}
                style={{ marginTop: 1 }}
              >
                {action?.label}
              </Button>
            </div>
          </ActionGrid>
        )}
      </Container>
    </Wrapper>
  );
}
