/**
 * Module dependencies.
 */

import { Carousel, useCarousel } from 'src/components/core/carousel';
import { Fragment } from 'react';
import { Svg } from 'src/components/core/svg';
import { Testimonial } from 'src/types/testimonial';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { useTranslation } from 'next-i18next';
import arrowLeft from 'src/assets/svg/arrow-left.svg';
import arrowRight from 'src/assets/svg/arrow-right.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  items: Testimonial[];
};

/**
 * `Controls` styled component.
 */

const Controls = styled.div`
  align-items: center;
  display: flex;
  padding-top: 48px;

  ${media.min.md`
    bottom: 0;
    justify-content: center;
    padding-top: 0;
    position: absolute;
    right: 0;
    transform: translateY(20%);
    z-index: 2;
  `}
`;

/**
 * `IconButton` styled component.
 */

const IconButton = styled(Svg).attrs(
  ({ disabled }: { disabled?: boolean }) => ({
    role: !disabled ? 'button' : undefined,
    size: '48px',
    tabIndex: !disabled ? 0 : undefined
  })
)<{ disabled?: boolean; onClick: () => void }>`
  transition: color var(--default-transition);

  ${ifProp(
    'disabled',
    `
    opacity: 0.4;
    pointer-events: none;
  `,
    `
    cursor: pointer;
  `
  )}
`;

/**
 * `TestimonialDescription` styled component.
 */

const TestimonialDescription = styled(Text).attrs({
  block: true,
  variant: 'subtitle1'
})`
  font-style: italic;

  &::after,
  &::before {
    content: '"';
  }
`;

/**
 * `Author` styled component.
 */

const Author = styled(Text).attrs({
  block: true,
  variant: 'small'
})`
  color: var(--color-sage700);
  margin-top: 1rem;

  ${media.min.md`
    margin-top: 3rem;
    padding-right: calc(100px + 10ch);
  `}
`;

/**
 * `CarouselControls` components.
 */

function CarouselControls() {
  const { carousel, carouselState } = useCarousel();
  const { t } = useTranslation();

  if (carousel.slides.length < 2) {
    return null;
  }

  return (
    <Controls role={'navigation'}>
      <IconButton
        aria-label={t('common:a11y.previous')}
        disabled={carouselState.isFirstSlide}
        icon={arrowLeft}
        onClick={() => {
          carousel.slidePrev();
        }}
      />

      <Text variant={'subtitle2'}>
        {`${carousel.realIndex + 1} / ${
          carousel.slides.length - (carousel?.loopedSlides ?? 0) * 2
        }`}
      </Text>

      <IconButton
        aria-label={t('common:a11y.next')}
        disabled={carouselState.isLastSlide}
        icon={arrowRight}
        onClick={() => {
          carousel.slideNext();
        }}
      />
    </Controls>
  );
}

/**
 * Export `TestimonialsCarousel` component.
 */

export function TestimonialsCarousel({ items }: Props) {
  return (
    <Carousel
      animation={'fadeText'}
      autoplay={{ delay: 3000 }}
      controlElements={<CarouselControls />}
    >
      {items.map(({ authorName, authorRole, description, id }) => (
        <Fragment key={id}>
          <TestimonialDescription>{description}</TestimonialDescription>

          <Author>{`${authorName}, ${authorRole}`}</Author>
        </Fragment>
      ))}
    </Carousel>
  );
}
