/**
 * Module dependencies.
 */

import { ParallaxImage } from 'src/components/core/image';
import { media } from 'src/styles/utils/media';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  centerImageUrl: string;
  leftImageUrl: string;
  rightImageUrl: string;
};

/**
 * `Grid` styled component.
 */

const Grid = styled.section`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 100%;
  overflow: hidden;
  padding: var(--vertical-spacing) var(--gutter) var(--vertical-spacing);
  position: relative;

  ${media.min.md`
    grid-gap: 2rem;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 6fr 5fr;
    padding: var(--vertical-spacing) 40px var(--vertical-spacing);
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  padding-top: 104%;
  position: relative;
`;

/**
 * `LefTopImage` styled component.
 */

const LefTopImage = styled(ImageWrapper)`
  ${media.min.md`
    grid-column: 1 / span 7;
    grid-row: 1 / span 1;
  `}
`;

/**
 * `RightBottomImage` styled component.
 */

const RightBottomImage = styled(ImageWrapper)`
  ${media.min.md`
    grid-column: -3 / span 7;
    grid-row: 2 / span 1;
  `}
`;

/**
 * `CenterImage` styled component.
 */

const CenterImage = styled(ImageWrapper)`
  ${media.min.md`
    grid-column: 8 / -3;
    grid-row: 1 / -1;
  `}
`;

/**
 * Export `ImageGrid` component.
 */

export const ImageGrid = (props: Props) => {
  const { centerImageUrl, leftImageUrl, rightImageUrl } = props;

  return (
    <Grid>
      <LefTopImage>
        <ParallaxImage
          alt={'Image 1'}
          data-scroll
          data-scroll-delay={0.66}
          data-scroll-direction={'vertical'}
          data-scroll-speed={-1}
          src={leftImageUrl}
        />
      </LefTopImage>

      <CenterImage>
        <ParallaxImage
          alt={'Image 2'}
          data-scroll
          data-scroll-delay={0.3}
          data-scroll-direction={'vertical'}
          data-scroll-speed={-2}
          src={centerImageUrl}
        />
      </CenterImage>

      <RightBottomImage>
        <ParallaxImage
          alt={'Image 3'}
          data-scroll
          data-scroll-delay={0.66}
          data-scroll-direction={'vertical'}
          data-scroll-speed={-1}
          src={rightImageUrl}
        />
      </RightBottomImage>
    </Grid>
  );
};
